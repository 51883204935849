var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExData),expression:"showExData"}],staticClass:"examination",staticStyle:{"display":"none"}},[_c('van-nav-bar',{attrs:{"title":_vm.examinTitle,"left-text":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"banner"}),(_vm.qsList1.length > 0)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"qsType"},[_c('span',{staticClass:"typeName"},[_vm._v(_vm._s(_vm.qsTypeIndexArr[_vm.qsList1[_vm.hisIdx].qsTypeIndex])+"、"+_vm._s(_vm.qsList1[_vm.hisIdx].qsType))]),(
          _vm.qsList1[_vm.hisIdx].qsType == '单选题' ||
          _vm.qsList1[_vm.hisIdx].qsType == '多选题' ||
          _vm.qsList1[_vm.hisIdx].qsType == '判断题'
        )?_c('span',{staticClass:"grade"},[_vm._v(" "+_vm._s(("(共" + (_vm.qsList1[_vm.hisIdx].icount) + "题，每题" + (_vm.qsList1[_vm.hisIdx].score) + "分，共" + (_vm.qsList1[_vm.hisIdx].totalscore) + "分)"))+" ")]):_vm._e(),(
          _vm.qsList1[_vm.hisIdx].qsType == '填空题' ||
          _vm.qsList1[_vm.hisIdx].qsType == '问答题'
        )?_c('span',{staticClass:"grade"},[_vm._v(" "+_vm._s(("(共" + (_vm.qsList1[_vm.hisIdx].icount) + "题，共" + (_vm.qsList1[_vm.hisIdx].totalscore) + "分)"))+" ")]):_vm._e()]),_c('div',{staticClass:"qsContent"},[_c('div',{staticClass:"qsTit"},[_vm._v(" "+_vm._s(((_vm.qsList1[_vm.hisIdx].sqnum + 1) + "." + (_vm.qsList1[_vm.hisIdx].qstitle)))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.qsList1[_vm.hisIdx].pic_guid),expression:"qsList1[hisIdx].pic_guid"}],staticClass:"title_pic"},[_c('van-image',{attrs:{"fit":"contain","src":_vm.qsList1[_vm.hisIdx].pic_guid
              ? _vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].pic_guid + '.png'
              : ''},on:{"click":function($event){return _vm.imagePreview(_vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].pic_guid + '.png')}}})],1),(_vm.qsList1[_vm.hisIdx].qsType == '单选题')?_c('van-radio-group',{staticClass:"singleChoice",on:{"change":_vm.radioAnswer},model:{value:(_vm.radioAs),callback:function ($$v) {_vm.radioAs=$$v},expression:"radioAs"}},_vm._l((_vm.qsList1[_vm.hisIdx].options),function(opt,opIdx){return _c('div',{key:opIdx},[_c('van-radio',{attrs:{"name":_vm.abList[opIdx]}},[_c('span',{staticClass:"iico"},[_vm._v(_vm._s(_vm.abList[opIdx]))]),_vm._v(_vm._s(opt))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.qsList1[_vm.hisIdx].item_pics[opIdx]),expression:"qsList1[hisIdx].item_pics[opIdx]"}],staticClass:"option_pic"},[_c('van-image',{attrs:{"width":"100%","height":"100%","src":_vm.qsList1[_vm.hisIdx].item_pics[opIdx]
                  ? _vm.filesvrurl +
                    '/' +
                    _vm.qsList1[_vm.hisIdx].item_pics[opIdx] +
                    '.png'
                  : ''},on:{"click":function($event){return _vm.imagePreview(
                  _vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].item_pics[opIdx] + '.png'
                )}}})],1)],1)}),0):(_vm.qsList1[_vm.hisIdx].qsType == '多选题')?_c('van-checkbox-group',{on:{"change":_vm.checkboxAnswer},model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},_vm._l((_vm.qsList1[_vm.hisIdx].options),function(opt,opIdx){return _c('div',{key:opIdx},[_c('van-checkbox',{attrs:{"name":_vm.abList[opIdx]}},[_c('span',{staticClass:"iico"},[_vm._v(_vm._s(_vm.abList[opIdx]))]),_vm._v(_vm._s(opt))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.qsList1[_vm.hisIdx].item_pics[opIdx]),expression:"qsList1[hisIdx].item_pics[opIdx]"}],staticClass:"option_pic"},[_c('van-image',{attrs:{"width":"100%","height":"100%","src":_vm.qsList1[_vm.hisIdx].item_pics[opIdx]
                  ? _vm.filesvrurl +
                    '/' +
                    _vm.qsList1[_vm.hisIdx].item_pics[opIdx] +
                    '.png'
                  : ''},on:{"click":function($event){return _vm.imagePreview(
                  _vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].item_pics[opIdx] + '.png'
                )}}})],1)],1)}),0):_vm._e(),(_vm.qsList1[_vm.hisIdx].qsType == '判断题')?_c('van-radio-group',{staticClass:"judge",on:{"change":_vm.judgeAnswer},model:{value:(_vm.judgeAs),callback:function ($$v) {_vm.judgeAs=$$v},expression:"judgeAs"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v(_vm._s(_vm.$t('examination.examinationTxt1')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.qsList1[_vm.hisIdx].item_pics[0]),expression:"qsList1[hisIdx].item_pics[0]"}],staticClass:"option_pic"},[_c('van-image',{attrs:{"width":"100%","height":"100%","src":_vm.qsList1[_vm.hisIdx].item_pics[0]
                ? _vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].item_pics[0] + '.png'
                : ''},on:{"click":function($event){return _vm.imagePreview(
                _vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].item_pics[0] + '.png'
              )}}})],1),_c('van-radio',{attrs:{"name":"0"}},[_vm._v(_vm._s(_vm.$t('examination.examinationTxt2')))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.qsList1[_vm.hisIdx].item_pics[1]),expression:"qsList1[hisIdx].item_pics[1]"}],staticClass:"option_pic"},[_c('van-image',{attrs:{"width":"100%","height":"100%","src":_vm.qsList1[_vm.hisIdx].item_pics[1]
                ? _vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].item_pics[1] + '.png'
                : ''},on:{"click":function($event){return _vm.imagePreview(
                _vm.filesvrurl + '/' + _vm.qsList1[_vm.hisIdx].item_pics[1] + '.png'
              )}}})],1)],1):_vm._e(),(_vm.qsList1[_vm.hisIdx].qsType == '填空题')?_c('van-cell-group',_vm._l((_vm.qsList1[_vm.hisIdx].qstitle.split('____').length -
          1),function(tk,tkIdx){return _c('van-field',{key:tkIdx,attrs:{"label":'空格' + parseInt(tkIdx + 1),"placeholder":_vm.$t('examination.examinationTxt3')},model:{value:(_vm.completions[tkIdx]),callback:function ($$v) {_vm.$set(_vm.completions, tkIdx, $$v)},expression:"completions[tkIdx]"}})}),1):_vm._e(),(_vm.qsList1[_vm.hisIdx].qsType == '问答题')?_c('van-field',{staticClass:"brief",attrs:{"rows":"10","autosize":"","label":"","type":"textarea","maxlength":"500","placeholder":_vm.$t('examination.examinationTxt4'),"show-word-limit":""},model:{value:(_vm.questionAs),callback:function ($$v) {_vm.questionAs=$$v},expression:"questionAs"}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"footer"},[_c('van-button',{attrs:{"type":"info","disabled":!_vm.cansave},on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.$t('examination.examinationTxt5')))])],1),_c('exitPrompt',{ref:"exitPrompt",attrs:{"noAnswer":_vm.noAnswer,"toPath":_vm.toPath}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }