<template>
  <!-- 退出答题提示 -->
  <van-dialog v-model="show"
              title=""
              :showConfirmButton="false">
    <van-icon name="cross"
              @click="close" />
    <div class="tp">
      <img src="@/assets/img/submitPrompt1.png"
           alt="">
    </div>
    <p>{{$t('submitPrompt.subTxt1')}}<span style="color:#F53838; margin:0 10px;">{{noAnswer}}</span></p>
    <p>{{$t('submitPrompt.subTxt2')}}<span style="color:#2B8DF0; margin:0 10px;">{{qsTotalNum-noAnswer}}</span></p>
    <div class="footer">
      <van-button type="info"
                  @click="submit">{{$t('submitPrompt.subTxt3')}}</van-button>
      <van-button type="default"
                  style="background:#CCCCCC; color:#fff;"
                  @click="goOn">{{$t('submitPrompt.subTxt4')}}</van-button>
    </div>
  </van-dialog>
</template>

<script>
export default {
  props: {
    noAnswer: {
      type: Number,
      default: 0
    },
    qsTotalNum: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      show: false,
    };
  },
  methods: {
    close () {
      this.show = false
    },
    submit () {
      this.$router.push({ path: '/submitPage', query: { isAnswer: this.qsTotalNum - this.noAnswer, noAnswer: this.noAnswer } })
    },
    goOn () {
      this.show = false
    },
  }
};
</script>

<style lang='less' scoped>
.van-dialog {
  width: 652px;
  height: 788px;
  font-family: Source Han Sans SC;
  overflow: visible;
  // /deep/.van-dialog__header{
  //  display: none;
  // }
  .van-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 40px;
    top: 32px;
  }

  /deep/.van-dialog__content {
    position: relative;
    padding-top: 330px;
    text-align: center;
    // height:100%;
    .tp {
      position: absolute;
      top: -240px;
      left: 50%;
      transform: translateX(-50%);
      width: 342px;
      height: 608px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    p {
      color: #333;
      font-size: 36px;
    }
    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-button {
        width: 50%;
      }
      .van-button:first-child {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
